import { getValidationRegex, addListener } from './utils';
import { sendHttpRequest } from './http';


// function utf8_to_b64(str) {
//     return window.btoa(unescape(encodeURIComponent(str)));
// }

function b64_to_utf8(str) {    
    return decodeURIComponent(escape(window.atob(str)));
}

export class RegisterView {
    constructor(document, window, routes) {
        this.window = window;
        this.firstNameInput = document.getElementById('signup-form-first-name');
        this.lastNameInput = document.getElementById('signup-form-last-name');
        this.emailInput = document.getElementById('signup-form-email');
        this.passwordInput = document.getElementById('signup-form-password');
        this.repeatPasswordInput = document.getElementById('signup-form-repeat-password');
        this.marketingConsentCheckbox = document.getElementById('signup-form-marketing-consent');
        this.organizationInput = document.getElementById('signup-form-organization');

        this.nameError = document.getElementById('signup-form-name-error');
        this.emailError = document.getElementById('signup-form-email-error');
        this.passwordError = document.getElementById('signup-form-password-error');
        this.repeatPasswordError = document.getElementById('signup-form-repeat-password-error');
        this.organizationError = document.getElementById('signup-form-organization-error');

        this.submitButton = document.getElementById('signup-form-submit');

        this.isDangerClass = 'is-danger';
        this.isInvisibleClass = 'is-invisible';

        this.errorMessageAttr = 'data-error-msg';
        this.errorEqualityMessageAttr = 'data-equal-error-msg';
        this.errorEmailInUseMessageAttr = 'data-inuse-error-msg';

        let invdata = new URLSearchParams(window.location.search).get('data') || '';
        if (invdata) {
            this.invdataBase64 = invdata;
            this.invdata = JSON.parse(b64_to_utf8(invdata))
            //alert(JSON.stringify(this.invdata));

            this.firstNameInput.value = this.invdata.firstName || '';
            // this.firstNameInput.disabled = true;

            this.lastNameInput.value = this.invdata.lastName || '';
            // this.lastNameInput.disabled = true;

            this.organizationInput.value = this.invdata.org || '';
            this.organizationInput.disabled = true;

            this.emailInput.value = this.invdata.email || '';
            this.emailInput.disabled = true;
        }


        this._setErrorMessages();

        this.routes = routes;
    }

    subscribeValidators = () => {
        addListener(this.firstNameInput, (event) => this._configureHandler(event, this.nameError));
        addListener(this.lastNameInput, (event) => this._configureHandler(event, this.nameError));
        addListener(this.emailInput, (event) => this._configureHandler (event, this.emailError));
        addListener(this.passwordInput, (event) => this._configureHandler(event, this.passwordError));
        addListener(this.organizationInput, (event) => this._configureHandler(event, this.organizationError, null, 'textnums'));
        addListener(
            this.repeatPasswordInput,
            (event) => this._configureHandler(
                event,
                this.repeatPasswordError,
                () => this._checkValueEquality(
                    event,
                    this.passwordInput.value,
                    event.target.value,
                    this.repeatPasswordError,
                    this.errorMessageAttr,
                    this.errorEqualityMessageAttr)));
    };

    subscribeHandlers = () => {
        addListener(this.submitButton, this._sendFormToServer, "click");
    };

    _setErrorMessages = () => {
        if(this.nameError) {
            this.nameError.textContent = this.nameError.getAttribute(this.errorMessageAttr);
        }

        if (this.emailError) {
            this.emailError.textContent = this.emailError.getAttribute(this.errorMessageAttr);
        }

        if (this.passwordError) {
            this.passwordError.textContent = this.passwordError.getAttribute(this.errorMessageAttr);
        }

        if (this.repeatPasswordError) {
            this.repeatPasswordError.textContent = this.repeatPasswordError.getAttribute(this.errorMessageAttr);
        }

        if(this.organizationError) {
            this.organizationError.textContent = this.organizationError.getAttribute(this.errorMessageAttr);
        }
    };

    _sendFormToServer = (event) => {
        event.stopPropagation();

        if (this.submitButton.readonly) {
            return;
        }

        this._hideError(this.emailInput, this.emailError);
        this.emailError.textContent = this.emailError.getAttribute(this.errorMessageAttr);
        const data = {
            firstName: this.firstNameInput.value,
            lastName: this.lastNameInput.value,
            email: this.emailInput.value,
            password: this.passwordInput.value,
            matchingPassword: this.repeatPasswordInput.value,
            sendMarketingEmails: this.marketingConsentCheckbox.checked,
            org: this.organizationInput.value,
        };

        if (this.invdata) {
            data.invitationData = this.invdataBase64;
        }

        if(this.invdata ? this._validatePassword(data) : this._validateData(data)) {

            this.submitButton.classList.add('is-loading');
            this.submitButton.readonly = true;

            sendHttpRequest('POST', this.routes.createUser, data)
                .then(() => {
                    if (data.invitationData)
                        this.window.location.href = this.routes.activated;
                    else
                        this.window.location.href = this.routes.regSuccess + '?email=' + data.email;
                })
                .catch((response) => {
                    const errorType = response ? response.errorType : '';
                    const errorMessage = response ? response.errorMessage : '';

                    if (errorType === "emailAlreadyInUse") {
                        this.emailError.textContent = this.emailError.getAttribute(this.errorEmailInUseMessageAttr);
                        this._showError(this.emailInput, this.emailError);
                    }
                    else if (errorType === "orgNameAlreadyInUse") {
                        this._showGenericError(errorMessage);
                    } else if (errorType === "invitationLinkExpired") {
                        this._showGenericError(errorMessage);
                    }
                    else {
                        this.window.location.href = this.routes.error;
                    }
                })
                .finally(() => {
                    this.submitButton.classList.remove('is-loading');
                    this.submitButton.readonly = false;
                })
            ;
        }
    };

    _configureHandler = (event, errorMessageElement, additionalHandler = null, regexType = null) => {
        event.stopPropagation();
        const control = event.target;
        const regex = getValidationRegex(regexType || control.type);

        if (regex.test(control.value) || control.value.length === 0) {
            this._hideError(control, errorMessageElement);
        } else {
            this._showError(control, errorMessageElement);
        }

        if(additionalHandler) {
            additionalHandler()
        }
    };

    _checkValueEquality = (event, original, repeated, errorElement, defaultErrorName, errorName) => {
        if(original === repeated) {
            this._hideError(event.target, errorElement);
            errorElement.textContent = errorElement.getAttribute(defaultErrorName);
        } else {
            this._showError(event.target, errorElement);
            errorElement.textContent = errorElement.getAttribute(errorName);
        }
    };

    _validatePassword = (data = {}) => {
        if (this._validate(data.password, 'password')) {
            this._hideError(this.passwordInput, this.passwordError);
        } else {
            this._showError(this.passwordInput, this.passwordError);
            return false;
        }

        if (this._validate(data.matchingPassword, 'password')) {
            this._hideError(this.repeatPasswordInput, this.repeatPasswordError);
        } else {
            this._showError(this.repeatPasswordInput, this.repeatPasswordError);
            return false;
        }

        if (data.password === data.matchingPassword) {
            this.repeatPasswordError.textContent = this.repeatPasswordError.getAttribute(this.errorMessageAttr);
            this._hideError(this.repeatPasswordInput, this.repeatPasswordError);
        } else {
            this.repeatPasswordError.textContent = this.repeatPasswordError.getAttribute(this.errorEqualityMessageAttr);
            this._showError(this.repeatPasswordInput, this.repeatPasswordError);
            return false;
        }

        return true;
    }

    _validateData = (data = {}) => {
        if (this._validate(data.firstName, 'text')) {
            this._hideError(this.firstNameInput, this.nameError);
        } else {
            this._showError(this.firstNameInput, this.nameError);
            return false;
        }

        if (this._validate(data.lastName, 'text')) {
            this._hideError(this.lastNameInput, this.nameError);
        } else {
            this._showError(this.lastNameInput, this.nameError);
            return false;
        }

        if (this._validate(data.org, 'textnums')) {
            this._hideError(this.organizationInput, this.organizationError);
        } else {
            this._showError(this.organizationInput, this.organizationError);
            return false;
        }

        if (this._validate(data.email, 'email')) {
            this._hideError(this.emailInput, this.emailError);
        } else {
            this._showError(this.emailInput, this.emailError);
            return false;
        }

        if (!this._validatePassword(data)) {
            return false;
        }

        return true;
    };

    _validate = (value, type) => {
        const regex = getValidationRegex(type);
        return regex.test(value) && value.length > 0;
    };

    _showError = (element, errorElement) => {
        element.classList.add(this.isDangerClass);
        errorElement.classList.remove(this.isInvisibleClass);
    };

    _showGenericError = (error) => {
        document.getElementById('iwoc-generic-error').classList.remove('is-invisible')
        document.getElementById('iwoc-generic-error-text').textContent = error;
    };

    _hideError = (element, errorElement) => {
        element.classList.remove(this.isDangerClass);
        errorElement.classList.add(this.isInvisibleClass);
    }
}