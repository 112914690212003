import React from 'react';
//import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import { HashRouter as Router } from 'react-router-dom';
import ErrorBoundary from './shared/error/error-boundary';
import AppRoutes from './routes';
import { ToastContainer, toast } from 'react-toastify';

// in production env styles are build by webpack as separate endpoints
if (process.env.NODE_ENV === 'development') {
  require('./App.scss');
}

function App() {
  return (
    <>
      <Router>
        <div className='app-container'>
          <ToastContainer
            position={toast.POSITION.TOP_LEFT}
            className='toastify-container'
            toastClassName='toastify-toast'
          />
          <div className='container view-container' id='app-view-container'>
            <ErrorBoundary>
              <AppRoutes />
            </ErrorBoundary>
            {/*<Footer />*/}
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;
