import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Row, Col, Label, Modal, ModalHeader, ModalBody} from 'reactstrap';

// @ts-ignore
import {AvForm, AvGroup, AvInput, AvFeedback} from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import {Translate} from 'react-jhipster';
import {IRootState} from '../shared/reducers';

import {getEntity, updateEntity, createEntity, reset} from './member.reducer';

export interface IMemberUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export interface IMemberUpdateState {
    isNew: boolean;
}

export class MemberUpdate extends React.Component<IMemberUpdateProps, IMemberUpdateState> {
    constructor(props: IMemberUpdateProps) {
        super(props);
        this.state = {
            isNew: !this.props.match.params || !this.props.match.params.id
        };
    }

    componentWillUpdate(nextProps: IMemberUpdateProps, nextState: IMemberUpdateState) {
        if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
            this.handleClose();
        }
    }

    componentDidMount() {
        if (this.state.isNew) {
            //this.props.reset();
        } else {
            this.props.getEntity(this.props.match.params.id);
        }
    }

    saveEntity = (event: any, errors: string | any[], values: any) => {
        if (errors.length === 0) {
            const {memberEntity} = this.props;
            const entity = {
                ...memberEntity,
                ...values
            };

            if (this.state.isNew) {
                this.props.createEntity(entity);
            } else {
                this.props.updateEntity(entity);
            }
        }
    };


    handleClose = (event?: any) => {
        if (event) {
            event.stopPropagation();
        }
        this.props.history.goBack();
    };


    render() {
        const {memberEntity, loading, updating} = this.props;
        const {isNew} = this.state;

        return (
            <Modal isOpen toggle={this.handleClose} centered={true}>
                <ModalHeader toggle={this.handleClose}>
                    <Translate contentKey="iwoc.member.home.addMember">Add member</Translate>
                </ModalHeader>
                <ModalBody>
                    <Row className="justify-content-center">
                        <Col md="12">
                            {loading ? (
                                <p>Loading...</p>
                            ) : (

                                <AvForm model={isNew ? {} : memberEntity} onSubmit={this.saveEntity}>

                                    <div className="mb-4 members-warn">
                                        <Translate contentKey="iwoc.member.home.warning">Note: all users inside of the organisation will
                                            be able to access all of patients, wounds and assessments documented.</Translate>
                                    </div>

                                    <AvGroup>
                                        <Label id="emailNameLabel" for="member-email">
                                            <Translate contentKey="iwoc.member.email">E-Mail</Translate>
                                        </Label>
                                        <AvInput id="member-email" type="email" name="email"
                                                 placeholder={"e.g. johndoe@mail.com"} required>
                                        </AvInput>
                                        <small className="form-text text-muted">
                                            <span>
                                                <Translate contentKey="iwoc.member.emailwillbe">An invitation email will be sent to this address</Translate>
                                            </span>
                                        </small>
                                        <AvFeedback><Translate contentKey="entity.validation.email">Please enter valid email</Translate></AvFeedback>
                                    </AvGroup>

                                    <AvGroup>
                                        <Label id="firstNameLabel" for="firstName">
                                            <Translate contentKey="iwoc.member.firstName">First Name</Translate>
                                        </Label>
                                        <AvInput id="member-firstName" type="text" name="firstName" required/>
                                        <AvFeedback><Translate contentKey="entity.validation.required">This field is required</Translate></AvFeedback>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label id="lastNameLabel" for="lastName">
                                            <Translate contentKey="iwoc.member.lastName">Last Name</Translate>
                                        </Label>
                                        <AvInput id="member-lastName" type="text" name="lastName" required/>
                                        <AvFeedback><Translate contentKey="entity.validation.required">This field is required</Translate></AvFeedback>
                                    </AvGroup>
                                    <div className="mt-4">
                                        <Button color="success" id="save-entity" type="submit" className="mr-1"
                                                disabled={updating}>
                                            <Translate contentKey="iwoc.member.home.addMember">Add member</Translate>
                                        </Button>

                                        <Button tag={Link} id="cancel-save" to="/members">
                                            <Translate contentKey="entity.action.cancel">Cancel</Translate>
                                        </Button>
                                    </div>
                                </AvForm>
                            )}
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    memberEntity: storeState.member.entity,
    loading: storeState.member.loading,
    updating: storeState.member.updating,
    updateSuccess: storeState.member.updateSuccess
});

const mapDispatchToProps = {
    getEntity,
    updateEntity,
    createEntity,
    reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberUpdate);
