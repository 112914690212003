import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';
import { debounce } from 'lodash';
import { useMemeber, MemeberContextValue } from './MemberContext';
const OrganizationDetail = () => {
  const {
    orgDetail,
    fetchOrganizationDetail,
    updateOrganizationDetail,
    isFetchingOrgDetail,
  } = useMemeber() as MemeberContextValue;

  const [orgInfo, setOrgInfo] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
  });
  const { name, email, phone, address } = orgInfo;

  useEffect(() => {
    fetchOrganizationDetail();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isFetchingOrgDetail && orgDetail) {
      setOrgInfo(orgDetail);
    }
    // eslint-disable-next-line
  }, [orgDetail, isFetchingOrgDetail]);

  const onChange = (e) => {
    e.persist();
    setOrgInfo({ ...orgInfo, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const debouncedFn = debounce(() => {
      updateOrganizationDetail(orgInfo);
    }, 500);
    debouncedFn();
    // eslint-disable-next-line
  }, [orgInfo]);

  const placeHolderText = () => {
    let placeHolderText = translate('entity.placeholder.optional');
    if (
      placeHolderText === 'translation-not-found[entity.placeholder.optional]'
    ) {
      return 'Optional';
    } else {
      return placeHolderText;
    }
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <Form>
        <FormGroup>
          <Label for='name'>
            <Translate contentKey='iwoc.member.name'>Name</Translate>
          </Label>
          <Input
            id='name'
            name='name'
            placeholder={placeHolderText()}
            type='text'
            onChange={onChange}
            value={name}
            disabled
            minLength={2}
            maxLength={150}
          />
        </FormGroup>

        <FormGroup>
          <Label for='exampleEmail'>
            <Translate contentKey='iwoc.member.emailAddress'>
              E-Mail Address
            </Translate>
          </Label>
          <Input
            id='exampleEmail'
            name='email'
            placeholder={placeHolderText()}
            type='email'
            onChange={onChange}
            value={email}
            minLength={3}
            maxLength={50}
          />
        </FormGroup>

        <FormGroup>
          <Label for='phone-number'>
            <Translate contentKey='iwoc.member.phoneNumber'>
              Phone Number
            </Translate>
          </Label>
          <Input
            id='phone-number'
            name='phone'
            placeholder={placeHolderText()}
            type='tel'
            onChange={onChange}
            value={phone}
            minLength={3}
            maxLength={20}
          />
        </FormGroup>
        <FormGroup>
          <Label for='address'>
            <Translate contentKey='iwoc.member.address'>Address</Translate>
          </Label>
          <Input
            id='address'
            name='address'
            placeholder={placeHolderText()}
            type='text'
            onChange={onChange}
            value={address}
            minLength={3}
            maxLength={210}
          />
        </FormGroup>
      </Form>
    </div>
  );
};

export default OrganizationDetail;
