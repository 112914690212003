import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';
import { debounce } from 'lodash';
import { useMemeber, MemeberContextValue } from './MemberContext';

const UserDetail = () => {
  const {
    fetchOrganizationDetail,
    userDetail,
    fetchUserDetail,
    isFetchingUserDetail,
    updateUserDetail,
  } = useMemeber() as MemeberContextValue;

  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    title: '',
    phone: '',
    address: '',
    email: '',
  });

  const { firstName, lastName, email, phone } = userInfo;

  useEffect(() => {
    fetchUserDetail();
    fetchOrganizationDetail();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isFetchingUserDetail) {
      setUserInfo(userDetail);
    }
    // eslint-disable-next-line
  }, [userDetail, isFetchingUserDetail]);

  const onChange = (e) => {
    e.persist();
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const debouncedFn = debounce(() => {
      updateUserDetail(userInfo);
    }, 500);
    debouncedFn();
    // eslint-disable-next-line
  }, [userInfo]);

  const placeHolderText = () => {
    let placeHolderText = translate('entity.placeholder.optional');
    if (
      placeHolderText === 'translation-not-found[entity.placeholder.optional]'
    ) {
      return 'Optional';
    } else {
      return placeHolderText;
    }
  };

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <h4>
          <Translate contentKey='iwoc.member.home.personalContact'>
            Personal Contact
          </Translate>
        </h4>
        <span>
          <Translate contentKey='iwoc.member.personalDetail'>
            Your contact details on the wound report.
          </Translate>
        </span>
      </div>
      <Form>
        <FormGroup>
          <Label for='exampleEmail'>
            <Translate contentKey='iwoc.member.emailAddress'>
              E-Mail Address
            </Translate>
          </Label>
          <Input
            id='exampleEmail'
            name='email'
            placeholder={placeHolderText()}
            type='email'
            value={email}
            disabled
            minLength={3}
            maxLength={50}
          />
        </FormGroup>
        <FormGroup>
          <Label for='firstName'>
            <Translate contentKey='iwoc.member.firstName'>First name</Translate>
          </Label>
          <Input
            id='firstName'
            name='firstName'
            placeholder={placeHolderText()}
            type='text'
            onChange={onChange}
            value={firstName}
            minLength={2}
            maxLength={150}
          />
        </FormGroup>

        <FormGroup>
          <Label for='last-name'>
            <Translate contentKey='iwoc.member.lastName'>Last name</Translate>
          </Label>
          <Input
            id='last-name'
            name='lastName'
            placeholder={placeHolderText()}
            type='text'
            onChange={onChange}
            value={lastName}
            minLength={2}
            maxLength={150}
          />
        </FormGroup>

        <FormGroup>
          <Label for='phone-number'>
            <Translate contentKey='iwoc.member.phoneNumber'>
              Phone Number
            </Translate>
          </Label>
          <Input
            id='phone-number'
            name='phone'
            placeholder={placeHolderText()}
            type='tel'
            onChange={onChange}
            value={phone}
            minLength={2}
            maxLength={20}
          />
        </FormGroup>
      </Form>
    </div>
  );
};

export default UserDetail;
