import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate} from 'react-jhipster';

import { IRootState } from '../shared/reducers';
import { getEntity, deleteEntity } from './member.reducer';

export interface IMemberDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ email: string }> {}

export class MemberDeleteDialog extends React.Component<IMemberDeleteDialogProps> {
  componentDidMount() {
    // this.props.getEntity(this.props.match.params.id);
  }

  confirmDelete = (event: any) => {
    this.props.deleteEntity(this.props.match.params.email);
    this.handleClose(event);
  };

  handleClose = (event: any) => {
    event.stopPropagation();
    this.props.history.goBack();
  };

  render() {
    // const { memberEntity } = this.props;
    return (
      <Modal isOpen toggle={this.handleClose} centered={true}>
        <ModalHeader toggle={this.handleClose}>
          <Translate contentKey="iwoc.member.delete.confirm">Remove member?</Translate>
        </ModalHeader>
        <ModalBody id="iwoc.member.delete.question">
          <Translate contentKey="iwoc.member.delete.question">
            Are you sure you want to delete this Member?
          </Translate>
        </ModalBody>
        <ModalFooter>
          <Button id="jhi-confirm-delete-member" color="danger" onClick={this.confirmDelete}>
              {/*<FontAwesomeIcon icon="trash" />&nbsp;*/}
              <Translate contentKey="entity.action.remove">Remove</Translate>
          </Button>
          <Button color="secondary" onClick={this.handleClose}>
            {/*<FontAwesomeIcon icon="ban" />&nbsp;*/}
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = ({ member }: IRootState) => {
    return {
        memberEntity: member.entity
    };
}

const mapDispatchToProps = { getEntity, deleteEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberDeleteDialog);
