// import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
// import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
// import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
// import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
// import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
// import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
// import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
// import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
// import { faHdd } from '@fortawesome/free-solid-svg-icons/faHdd';
// import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
// import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
// import { faList } from '@fortawesome/free-solid-svg-icons/faList';
// import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';
// import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
// import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
// import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
// import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
// import { faThList } from '@fortawesome/free-solid-svg-icons/faThList';
// import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
// import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
// import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
// import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
// import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
// import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
// import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
// import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
// import { faRoad } from '@fortawesome/free-solid-svg-icons/faRoad';
// import { faCloud } from '@fortawesome/free-solid-svg-icons/faCloud';

import { library } from '@fortawesome/fontawesome-svg-core';

export const loadIcons = () => {
  library.add(
    // faSort,
    // faEye,
    // faSync,
    // faBan,
    faTrash,
      faDownload,
      faUpload
    // faArrowLeft,
    // faSave,
    // faPlus,
    // faPencilAlt,
    // faUser,
    // faTachometerAlt,
    // faHeart,
    // faList,
    // faTasks,
    // faBook,
    // faHdd,
    // faClock,
    // faSignInAlt,
    // faSignOutAlt,
    // faWrench,
    // faThList,
    // faUserPlus,
    // faAsterisk,
    // faFlag,
    // faBell,
    // faHome,
    // faRoad,
    // faCloud,
    // faTimesCircle,
    // faSearch
  );
};
