
export const getValidationRegex = (type) => {
    switch (type) {
        case "text": return /^[a-zA-ZÀ-ž]+(?:[_ -]?[a-zA-ZÀ-ž])*$/;
        case "textnums": return /^[a-zA-ZÀ-ž]+([_ -]?[a-zA-ZÀ-ž]?\d?)*$/;
        //case "email": return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\b/i;
        case "email": return /.+@.+$/;
        case "password": return /(?=.*\d)(?=.*[A-Z]).{8,}$/;
        default: return '.';
    }
};

export const validate = (value, type, checkLength = true) => {
    const regex = getValidationRegex(type);
    return checkLength
        ? regex.test(value) && value.length > 0
        : regex.test(value) || value.length === 0;
};

export const addListener = (element, handler, event = "focusout") => {
    if(element) {
        element.addEventListener(event, handler);
    }
};

export const getBaseUrl = () => {
    return '.';
    //return 'https://cors-anywhere.herokuapp.com/http://imitoconnect-dev.imito.io:8090';
};