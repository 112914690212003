import React, { useState } from "react";
import { FormGroup, Label, Input, Button, Alert } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { Translate, translate } from "react-jhipster";
import axios from "axios";

import { ImitoIcon } from "../../assets";
import { lang } from "../../config/translation";

const Signup = (props) => {
  const [form, setForm] = useState({
    firstName: "",
    secondName: "",
    organization: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    marketingConsent: true,
    introductionConsent: "",
  });

  const [regError, setRegError] = useState({
    regErrorState: false,
    regErrorMessage: "",
  });

  const { regErrorState, regErrorMessage } = regError;

  const {
    firstName,
    secondName,
    organization,
    email,
    phone,
    password,
    confirmPassword,
    marketingConsent,
    introductionConsent,
  } = form;

  const placeHolderText = () => {
    let placeHolderText = translate("entity.placeholder.optional");
    if (
      placeHolderText === "translation-not-found[entity.placeholder.optional]"
    ) {
      return "Optional";
    } else {
      return placeHolderText;
    }
  };

  const onChange = (e) => {
    if (e.target.type === "checkbox") {
      setForm({ ...form, [e.target.name]: [e.target.checked] });
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const reqParams = {
      firstName,
      lastName: secondName,
      org: organization,
      email,
      phone,
      password,
      matchingPassword: confirmPassword,
      sendMarketingEmails: marketingConsent,
    };

    try {
      const res = await axios.post("reg/v2/create", reqParams, {
        headers: { "Accept-Language": lang },
      });
      if (res.status === 200) {
        window.location.replace(`/reg/success/?email=${email}`);
      }
    } catch (error) {
      if (
        error.response.data.errorType === "emailAlreadyInUse" ||
        error.response.data.errorType === "orgNameAlreadyInUse"
      ) {
        setRegError({
          ...regError,
          regErrorState: true,
          regErrorMessage: error.response.data.errorMessage,
        });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setTimeout(() => {
          setRegError({ regErrorState: false, regErrorMessage: "" });
        }, 8000);
      } else {
        window.location.replace(`/error`);
      }
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          margin: "20px",
          height: "20px",
        }}
      >
        <ImitoIcon />
      </div>
      <div
        style={{
          marginTop: "250px",
          padding: "80px 5px 20px",
          width: "60%",
          margin: "0 auto",
          // border: '2px solid red',
        }}
      >
        <h1 style={{ textAlign: "center" }}>
          <Translate contentKey="reg.header.imitoAccount">
            Create an imitoWound account
          </Translate>
        </h1>
        <h5 style={{ textAlign: "center" }}>
          <Translate contentKey="reg.header.signin">
            Already have an account? Sign in via the imitoWound app.
          </Translate>
        </h5>
        {regErrorState ? <Alert color="danger">{regErrorMessage}</Alert> : null}
        <AvForm onValidSubmit={onSubmit}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <AvGroup style={{ width: "45%" }}>
              <Label for="firstName">
                <Translate contentKey="iwoc.member.firstName">
                  First Name:
                </Translate>
              </Label>
              <AvField
                id="firstName"
                name="firstName"
                type="text"
                value={firstName}
                onChange={onChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: translate("entity.validation.required"),
                  },
                  pattern: {
                    value: "^[a-zA-ZÀ-ž]+(?:[_ -]?[a-zA-ZÀ-ž])*$",
                    errorMessage: translate("input.validation.name"),
                  },
                  minLength: {
                    value: 2,
                    errorMessage: translate("entity.validation.minlength", {
                      min: 2,
                    }),
                  },
                  maxLength: {
                    value: 16,
                    errorMessage: translate("entity.validation.maxlength", {
                      max: 16,
                    }),
                  },
                }}
              />
            </AvGroup>
            <AvGroup style={{ width: "45%" }}>
              <Label for="secondName">
                <Translate contentKey="iwoc.member.lastName">
                  Last Name
                </Translate>
              </Label>
              <AvField
                id="secondName"
                name="secondName"
                type="text"
                value={secondName}
                onChange={onChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: translate("entity.validation.required"),
                  },
                  pattern: {
                    value: "^[a-zA-ZÀ-ž]+(?:[_ -]?[a-zA-ZÀ-ž])*$",
                    errorMessage: translate("input.validation.name"),
                  },
                  minLength: {
                    value: 2,
                    errorMessage: translate("entity.validation.minlength", {
                      min: 2,
                    }),
                  },
                  maxLength: {
                    value: 16,
                    errorMessage: translate("entity.validation.maxlength", {
                      max: 16,
                    }),
                  },
                }}
              />
            </AvGroup>
          </div>

          <AvGroup>
            <Label for="organization">
              <Translate contentKey="form.input.organization">
                Organization
              </Translate>
            </Label>
            <AvField
              id="organization"
              name="organization"
              type="text"
              onChange={onChange}
              value={organization}
              validate={{
                required: {
                  value: true,
                  errorMessage: translate("entity.validation.required"),
                },
                pattern: {
                  value: /^[a-zA-ZÀ-ž0-9]+([_ -]?[a-zA-ZÀ-ž0-9]?\d?)*$/,
                  errorMessage: translate("input.validation.organization"),
                },
                minLength: {
                  value: 2,
                  errorMessage: translate("entity.validation.minlength", {
                    min: 2,
                  }),
                },
                maxLength: {
                  value: 16,
                  errorMessage: translate("entity.validation.maxlength", {
                    max: 16,
                  }),
                },
              }}
            />
          </AvGroup>
          <AvGroup>
            <Label for="email">
              <Translate contentKey="form.input.email">Email</Translate>
            </Label>
            <AvField
              id="email"
              name="email"
              type="email"
              onChange={onChange}
              value={email}
              validate={{
                required: {
                  value: true,
                  errorMessage: translate("entity.validation.required"),
                },
                pattern: {
                  value: ".+@.+$",
                  errorMessage: translate("entity.validation.email"),
                },
              }}
            />
          </AvGroup>
          <FormGroup>
            <Label for="phone-number">
              <Translate contentKey="iwoc.member.phoneNumber">
                Phone Number
              </Translate>
            </Label>
            <Input
              id="phone-number"
              name="phone"
              type="tel"
              onChange={onChange}
              placeholder={placeHolderText()}
              value={phone}
              minLength={3}
              maxLength={20}
            />
          </FormGroup>
          <AvGroup>
            <Label for="password">
              <Translate contentKey="form.input.password">Password</Translate>
            </Label>
            <AvField
              id="password"
              name="password"
              type="password"
              onChange={onChange}
              value={password}
              validate={{
                required: {
                  value: true,
                  errorMessage: translate("entity.validation.required"),
                },
                pattern: {
                  value: /(?=.*\d)(?=.*[A-Z]).{8,}$/,
                  errorMessage: translate("entity.validation.password"),
                },
              }}
            />
          </AvGroup>
          <AvGroup>
            <Label for="confirmPassword">
              <Translate contentKey="form.input.confirmPassword">
                Confirm password
              </Translate>
            </Label>
            <AvInput
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              onChange={onChange}
              value={confirmPassword}
              validate={{
                required: {
                  value: true,
                  errorMessage: translate("entity.validation.required"),
                },
                match: {
                  value: "password",
                  errorMessage: translate(
                    "entity.validation.passwordDoesntMatch"
                  ),
                },
              }}
            />
          </AvGroup>
          <div>
            <p>
              <Translate contentKey="reg.personalIntroduction">
                Would you like a personal introduction to imitoWound?
              </Translate>
            </p>
          </div>
          <FormGroup check>
            <Input
              type="checkbox"
              name="introductionConsent"
              onChange={onChange}
              value={introductionConsent}
            />{" "}
            <Label check>
              <Translate contentKey="reg.noIntroduction">
                No thanks, i will try out the app myself
              </Translate>
            </Label>
          </FormGroup>
          <iframe
            title="customer-booking"
            width="100%"
            height="500px"
            src="https://imito.zohobookings.eu/portal-embed#/customer/79814000002184082"
            frameBorder="0"
            style={{ marginTop: "20px" }}
            // allowFullScreen=''
          >
            {" "}
          </iframe>
          <div>
            <p>
              <Translate contentKey="reg.termsAndConditions">
                By signing up, you agree to imitoWound{" "}
              </Translate>
              <a href="https://imito.io/en/imitowound-conditions-terms">
                <Translate contentKey="link.termsAndCondition">
                  Terms & Conditions
                </Translate>
              </a>
            </p>
            <p>
              <Translate contentKey="reg.note">Note: the imitoWound </Translate>
              <a href="https://imito.io/data-privacy">
                <Translate contentKey="link.privacyPolicy">
                  Privacy Policy{" "}
                </Translate>
              </a>
              <Translate contentKey="reg.dataDescription"></Translate>
              describes how data is handled in this service.
            </p>
          </div>
          <FormGroup check>
            <Input
              type="checkbox"
              name="marketingConsent"
              onChange={onChange}
              checked={marketingConsent}
            />{" "}
            <Label check>
              {" "}
              <Translate contentKey="reg.stayUpToDate">
                I want to stay up-to-date and agree to receive information about
                imito products, services and events.{" "}
              </Translate>
            </Label>
          </FormGroup>
          <FormGroup>
            <Button
              style={{
                width: "100%",
                background: "#e2352a",
                color: "#ffffff",
                border: "none",
                marginTop: "20px",
              }}
            >
              <Translate contentKey="form.action.submit">Submit</Translate>
            </Button>
          </FormGroup>
        </AvForm>
      </div>
    </>
  );
};

export default Signup;
