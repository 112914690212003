// import axios from 'axios';

import { TranslatorContext } from 'react-jhipster';

export const ACTION_TYPES = {
  SET_LOCALE: 'locale/SET_LOCALE'
};

const initialState = {
  currentLocale: undefined
};

export type LocaleState = Readonly<typeof initialState>;

export default (state: LocaleState = initialState, action: { type: any; locale: any; }): LocaleState => {
  switch (action.type) {
    case ACTION_TYPES.SET_LOCALE:
      const currentLocale = action.locale;
      if (state.currentLocale !== currentLocale) {
        TranslatorContext.setLocale(currentLocale);
      }
      return {
        currentLocale
      };
    default:
      return state;
  }
};

export const setLocale = (locale: string) => async (dispatch: (arg0: { type: string; locale: string; }) => void) => {
  if (!Object.keys(TranslatorContext.context.translations).includes(locale)) {
    let json = require(`../../i18n/${locale}.json`);
      TranslatorContext.registerTranslations(locale, json);
  }
  dispatch({
    type: ACTION_TYPES.SET_LOCALE,
    locale
  });
};
