import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from '../shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from '../shared/reducers/action-type.util';

import { IMember, defaultValue } from '../shared/model/member.model';
import {lang} from '../config/translation';
import _ from 'lodash';

export const ACTION_TYPES = {
  FETCH_MEMBER_LIST: 'member/FETCH_MEMBER_LIST',
  FETCH_MEMBER: 'member/FETCH_MEMBER',
  CREATE_MEMBER: 'member/CREATE_MEMBER',
  UPDATE_MEMBER: 'member/UPDATE_MEMBER',
  DELETE_MEMBER: 'member/DELETE_MEMBER',
  RESET: 'member/RESET',
  GET_MEMBER_LOGO: 'GET_MEMBER_LOGO',
  DELETE_MEMBER_LOGO: 'DELETE_MEMBER_LOGO',
  UPDATE_MEMBER_LOGO: 'UPDATE_MEMBER_LOGO',
};

const initialState = {
  loading: false,
  errorMessage: null,
  isAdmin: false,
  entities: [] as ReadonlyArray<IMember>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  permissionError: false,
  memberLogo: null,
};

export type MemberState = Readonly<typeof initialState>;

// Reducer

export default (state: MemberState = initialState, action: any): MemberState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_MEMBER_LIST):
    case REQUEST(ACTION_TYPES.FETCH_MEMBER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_MEMBER):
    case REQUEST(ACTION_TYPES.UPDATE_MEMBER):
    case REQUEST(ACTION_TYPES.DELETE_MEMBER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_MEMBER_LIST):
        return {
            ...state,
            loading: false,
            updating: false,
            updateSuccess: false,
            errorMessage: action.payload,
            permissionError: _.get(action, 'payload.response.data.errorType') === 'permissionError'
        };
    case FAILURE(ACTION_TYPES.FETCH_MEMBER):
    case FAILURE(ACTION_TYPES.CREATE_MEMBER):
    case FAILURE(ACTION_TYPES.UPDATE_MEMBER):
    case FAILURE(ACTION_TYPES.GET_MEMBER_LOGO):
    case FAILURE(ACTION_TYPES.DELETE_MEMBER):
        return {
            ...state,
            loading: false,
            updating: false,
            updateSuccess: false,
            errorMessage: action.payload
        };
    case SUCCESS(ACTION_TYPES.FETCH_MEMBER_LIST):
      return {
        ...state,
        loading: false,
        totalItems: _.size(action.payload.data.members), //action.payload.headers['x-total-count'],
        entities: action.payload.data.members,
        isAdmin:  action.payload.data.isAdmin,
        permissionError: false
        // entities: [{id: 1, firstName: 'John', lastName: 'Smith', email: 'john@mail.ru'} as IMember,
        //     {id: 2, firstName: 'Jane', lastName: 'Swansen', email: 'jane@mail.ru'} as IMember,
        //     {id: 3, firstName: 'Vicky', lastName: 'Brooks', email: 'vicky@mail.ru'} as IMember,
        //]// action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_MEMBER):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_MEMBER):
    case SUCCESS(ACTION_TYPES.UPDATE_MEMBER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_MEMBER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.GET_MEMBER_LOGO:
      return {
        ...state,
        memberLogo: action.payload
      };
    case ACTION_TYPES.UPDATE_MEMBER_LOGO:
      return {
        ...state,
        memberLogo: action.payload
      };
    default:
      return state;
  }
};

const apiUrl = 'org/members';
const token = new URLSearchParams(window.location.search).get('token') || '';
// Actions

// @ts-ignore
export const getEntities: ICrudGetAllAction<IMember> = (page, size, sort) => {
  const requestUrl = `${apiUrl}`;

  return {
    type: ACTION_TYPES.FETCH_MEMBER_LIST,
      payload: axios.get<IMember>(`${requestUrl}?token=${token}`, {headers: {'Accept-Language': lang}})
      // payload: Promise.resolve()
  };
};

export const getEntity: ICrudGetAction<IMember> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_MEMBER,
    payload: axios.get<IMember>(requestUrl, {headers: {'Accept-Language': lang}})
  };
};

export const createEntity: ICrudPutAction<IMember> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_MEMBER,
    payload: axios.post(`/org/member/invite`, _.extend(cleanEntity(entity), {userToken: token}), {headers: {'Accept-Language': lang}})
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IMember> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_MEMBER,
    payload: axios.put(apiUrl, cleanEntity(entity), {headers: {'Accept-Language': lang}})
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IMember> = email => async dispatch => {
  const requestUrl = `/org/member`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_MEMBER,
    payload: axios.delete(requestUrl, { data: {email: email, userToken: token}, headers: {'Accept-Language': lang}})
  });
  dispatch(getEntities());
  return result;
};

export const getMemberLogo = () => (dispatch) => {
  const curUrl = 'org/logo';
  const requestOptions: any = {
    responseType: 'arraybuffer',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
  };

  axios.get(`${curUrl}`, requestOptions)
      .then((res) => {
          const b64 = Buffer.from(res.data, 'binary').toString('base64');
          dispatch({
            type: ACTION_TYPES.GET_MEMBER_LOGO,
            payload: b64,
          })
        })
      .catch((err) => console.log(err))
}
export const resetLogo = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_MEMBER_LOGO,
    payload: null
  })
}
export const deleteMemberLogo = () => (dispatch) => {
  const curUrl = 'org/logo';
  const requestOptions = {
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  };
  axios.delete(`${curUrl}`,requestOptions)
      .then((res) => {
        console.log(res)
      })
}

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
