import { addListener, validate } from './utils';
import { sendHttpRequest } from './http';

export class ResetPassword {
    constructor(document, window, routes) {
        this.window = window;
        this.emailInput = document.getElementById('reset-password-ask-email-address');
        this.emailError = document.getElementById('reset-password-ask-email-error');

        this.newPasswordInput = document.getElementById('reset-password-password');
        this.newPasswordError = document.getElementById('reset-password-password-error');

        this.repeatPasswordInput = document.getElementById('reset-password-repeat-password');
        this.repeatPasswordError = document.getElementById('reset-password-repeat-password-error');

        this.submitButton = document.getElementById('reset-password-ask-email-button');
        this.resetPasswordButton = document.getElementById('reset-password-submitButton');

        this.isDangerClass = 'is-danger';
        this.isInvisibleClass = 'is-invisible';

        this.errorMessageAttr = 'data-error-msg';
        this.emailNotFoundErrorMessageAttr = 'data-email-not-found-error-msg';
        this.errorMessageNotEqualAttr = 'data-equal-error-msg';
        this._setErrorMessages();

        this.routes = routes;
    }

    subscribeValidators = () => {
        addListener(this.emailInput, this._emailHandler);
        addListener(this.newPasswordInput, this._passwordHandler);
        addListener(this.repeatPasswordInput, this._repeatPasswordHandler);
    };

    subscribeHandlers = () => {
        addListener(this.submitButton, this._sendEmailFormToServer, "click");
        addListener(this.resetPasswordButton, this._sendResetPasswordFormToServer, "click");
    };

    _setErrorMessages = () => {
        if (this.newPasswordError) {
            this.newPasswordError.textContent = this.newPasswordError.getAttribute(this.errorMessageAttr);
        }

        if (this.repeatPasswordError) {
            this.repeatPasswordError.textContent = this.repeatPasswordError.getAttribute(this.errorMessageAttr);
        }

        if (this.emailError) {
            this.emailError.textContent = this.emailError.getAttribute(this.errorMessageAttr);
        }
    };

    _emailHandler = (event) => {
        event.stopPropagation();
        const control = event.target;

        if (validate(control.value, control.type, false)) {
            this._hideError(control, this.emailError);
        } else {
            this._showError(control, this.emailError);
        }
    };

    _passwordHandler = (event) => {
        event.stopPropagation();
        const control = event.target;

        if (validate(control.value, control.type, false)) {
            this._hideError(control, this.newPasswordError);
        } else {
            this._showError(control, this.newPasswordError);
        }
    };

    _repeatPasswordHandler = (event) => {
        event.stopPropagation();
        const control = event.target;

        if (validate(control.value, control.type, false)) {
            this._hideError(control, this.repeatPasswordError);
        } else {
            this._showError(control, this.repeatPasswordError);
        }

        if(control.value.length > 0) {
            if (control.value === this.newPasswordInput.value) {
                this._hideError(event.target, this.repeatPasswordError);
                this.repeatPasswordError.textContent = this.repeatPasswordError.getAttribute(this.errorMessageAttr);
            } else {
                this._showError(event.target, this.repeatPasswordError);
                this.repeatPasswordError.textContent = this.repeatPasswordError.getAttribute(this.errorMessageNotEqualAttr);
            }
        }
    };

    _sendEmailFormToServer = (event) => {
        event.stopPropagation();

        this._hideError(this.emailInput, this.emailError);
        this.emailError.textContent = this.emailError.getAttribute(this.errorMessageAttr);

        const data = {
            email: this.emailInput.value
        };

        if(this._validateEmailData(data)) {
            sendHttpRequest('POST', this.routes.reset.sendLink, data)
                .then(() => {
                    this.window.location.href = this.routes.reset.emailSent;
                })
                .catch((response) => {
                    const errorType = response.errorType;
                    if (errorType === "emailNotFound") {
                        this.emailError.textContent = this.emailError.getAttribute(this.emailNotFoundErrorMessageAttr);
                        this._showError(this.emailInput, this.emailError);
                    }
                    else if (errorType === 'internal') {
                        this.window.location.href = this.routes.error;
                    }
                });
        }
    };

    _sendResetPasswordFormToServer = (event) => {
        event.stopPropagation();

        const data = {
            newPassword: this.newPasswordInput.value,
            repeatPassword: this.repeatPasswordInput.value,
        };

        if(this._validateResetPasswordData(data)) {
            const pwd = {
                password: data.newPassword,
                matchingPassword: data.repeatPassword,
            };
            const token = document.getElementById('token').innerText;
            sendHttpRequest('POST', this.routes.reset.setPassword  + token, pwd)
                .then(() => {
                    this.window.location.href = this.routes.reset.success;
                })
                .catch((response) => {
                    this.window.location.href = this.routes.error;
                });
        }
    };

    _validateEmailData = (data = {}) => {
        if (validate(data.email, 'email')) {
            this._hideError(this.emailInput, this.emailError);
        } else {
            this._showError(this.emailInput, this.emailError);
            return false;
        }

        return true;
    };

    _validateResetPasswordData = (data = {}) => {
        if (validate(data.newPassword, 'password')) {
            this._hideError(this.newPasswordInput, this.newPasswordError);
        } else {
            this._showError(this.newPasswordInput, this.newPasswordError);
            return false;
        }

        if (validate(data.repeatPassword, 'password')) {
            this._hideError(this.repeatPasswordInput, this.repeatPasswordError);
        } else {
            this._showError(this.repeatPasswordInput, this.repeatPasswordError);
            return false;
        }

        if (data.newPassword === data.repeatPassword) {
            this.repeatPasswordError.textContent = this.repeatPasswordError.getAttribute(this.errorMessageAttr);
            this._hideError(this.repeatPasswordInput, this.repeatPasswordError);
        } else {
            this.repeatPasswordError.textContent = this.repeatPasswordError.getAttribute(this.errorMessageNotEqualAttr);
            this._showError(this.repeatPasswordInput, this.repeatPasswordError);
            return false;
        }

        return true;
    };

    _showError = (element, errorElement) => {
        element.classList.add(this.isDangerClass);
        errorElement.classList.remove(this.isInvisibleClass);
    };

    _hideError = (element, errorElement) => {
        element.classList.remove(this.isDangerClass);
        errorElement.classList.add(this.isInvisibleClass);
    };
}