import { RegisterView } from './register';
import { ResetPassword } from "./resetPassword";
import { RegistrationSuccess } from './registration-success';
//require('./custom.scss');

if (process.env.NODE_ENV === 'development') {
    require('./custom.scss');
}


(function(window, document) {

    const routes = {
        createUser: '/reg/create',
        error: '/error',
        regSuccess: '/reg/success',
        regResend: '/reg/resend',
        activated: '/reg/activated',
        reset: {
            sendLink: '/r-pwd/send-link',
            emailSent: '/r-pwd/email-sent',
            setPassword: '/r-pwd/reset/',
            success: '/r-pwd/success'
        }
    };

    const registerView = new RegisterView(document, window, routes);
    const resetPasswordView = new ResetPassword(document, window, routes);
    const registrationSuccess = new RegistrationSuccess(document, window, routes);

    registerView.subscribeValidators();
    registerView.subscribeHandlers();
    registrationSuccess.subscribeHandlers();

    resetPasswordView.subscribeValidators();
    resetPasswordView.subscribeHandlers();

})(window, document);

