import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
interface IUserDetail {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  title: string;
  address: string;
}
interface IOrgDetail {
  name: string;
  email: string;
  phone: string;
  address: string;
}
export type MemeberContextValue = {
  isFetchingOrgDetail: Boolean;
  orgDetail: IOrgDetail;
  fetchOrganizationDetail: () => void;

  isFetchingUserDetail: Boolean;
  userDetail: IUserDetail;
  fetchUserDetail: () => void;

  updateOrganizationDetail: (orgDetail: IOrgDetail) => void;
  updateUserDetail: (userDetail: IUserDetail) => void;
};

const MemberContext = createContext<undefined | MemeberContextValue>(undefined);

export const useMemeber = () => useContext(MemberContext);

const token = new URLSearchParams(window.location.search).get('token') || '';

const MemberProvider = ({ children }: any) => {
  const [orgDetail, setOrgDetail] = useState<IOrgDetail>(null);
  const [isFetchingOrgDetail, setIsFetchingOrgDetail] = useState<Boolean>(true);

  const [userDetail, setUserDetail] = useState<IUserDetail>();
  const [isFetchingUserDetail, setIsFetchingUserDetail] =
    useState<Boolean>(true);

  const fetchOrganizationDetail = async () => {
    try {
      setIsFetchingOrgDetail(true);
      const res = await axios.get('org/details', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      setOrgDetail(res.data);
      setIsFetchingOrgDetail(false);
    } catch (error) {
      console.log(error);
      setIsFetchingOrgDetail(false);
    }
  };
  const fetchUserDetail = async () => {
    try {
      setIsFetchingUserDetail(true);
      const res = await axios.get('user/details', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      setUserDetail(res.data);

      setIsFetchingUserDetail(false);
    } catch (error) {
      console.log(error);
      console.log('error fetching user data');
    }
  };

  const updateOrganizationDetail = async (formData) => {
    if (formData.name === '') {
      return;
    }
    // console.log('form data org', formData);
    try {
      // eslint-disable-next-line
      const res = await axios.put('org/details', formData, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
    } catch (error) {
      console.log('error updating org detail');
    }
  };
  const updateUserDetail = async (formData) => {
    if (formData.email === '') {
      return;
    }
    // console.log('form data userDetail', formData);
    try {
      // eslint-disable-next-line
      const res = await axios.put('user/details', formData, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
    } catch (error) {
      console.log('error updating org detail');
    }
  };

  return (
    <MemberContext.Provider
      value={{
        isFetchingUserDetail,
        fetchUserDetail,
        userDetail,
        isFetchingOrgDetail,
        fetchOrganizationDetail,
        orgDetail,
        updateOrganizationDetail,
        updateUserDetail,
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};

export default MemberProvider;
