import React, { useState } from "react";
import { FormGroup, Label, Button, Alert, Spinner } from "reactstrap";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Translate, translate } from "react-jhipster";
import axios from "axios";
import { ImitoIcon } from "../../assets";
import { lang } from "../../config/translation";
import { DACHCountries, otherCountries } from "./countries";

const EventReg = () => {
  let token = new URLSearchParams(window.location.search).get("token");
  let eventName = new URLSearchParams(window.location.search).get("eventName");

  const search: Record<string, string> =
    window.location.hash
      ?.split("?")?.[1]
      ?.split("&")
      ?.reduce((a, c) => {
        const paramName = c.split("=")?.[0];
        a[paramName] = c.split("=")?.[1];
        return a;
      }, {}) || {};

  token = search?.token || token;
  eventName = search?.eventName || eventName;

  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    organization: "",
    email: "",
    confirmEmail: "",
    position: "",
    countryCode: "",
  });

  const [regError, setRegError] = useState({
    regErrorState: false,
    regErrorMessage: "",
  });

  const { regErrorState, regErrorMessage } = regError;

  const {
    firstName,
    lastName,
    organization,
    email,
    confirmEmail,
    position,
    countryCode,
  } = form;

  const placeHolderText = () => {
    let placeHolderText = translate("entity.placeholder.optional");
    if (
      placeHolderText === "translation-not-found[entity.placeholder.optional]"
    ) {
      return "Optional";
    } else {
      return placeHolderText;
    }
  };

  const onChange = (e) => {
    if (e.target.type === "checkbox") {
      setForm({ ...form, [e.target.name]: [e.target.checked] });
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const reqParams = {
      firstName,
      lastName,
      org: organization,
      eventCode: eventName,
      sendMarketingEmails: false,
      email,
      position,
      countryCode,
    };
    const reqHeaders = {
      "Accept-Language": lang,
      Authorization: token ? `Bearer ${token}` : undefined,
    };
    console.log("token", token);
    console.log("eventName", eventName);
    if (reqHeaders.Authorization === undefined) {
      delete reqHeaders["Authorization"];
    }
    if (!reqParams.eventCode) {
      delete reqParams["eventCode"];
    }
    if (!reqParams.position) {
      delete reqParams["position"];
    }
    try {
      const res = await axios.post("reg/events/create", reqParams, {
        headers: reqHeaders,
      });
      if (res.status === 200) {
        setLoading(false);
        window.location.replace(`/app?lang=${lang}#/registration/completed/`);
      }
    } catch (error) {
      if (error.response.data.errorType === "emailAlreadyInUse") {
        setRegError({
          ...regError,
          regErrorState: true,
          regErrorMessage: translate("error.message.emailAlreadyInUse"),
        });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setTimeout(() => {
          setRegError({ regErrorState: false, regErrorMessage: "" });
        }, 8000);

        setLoading(false);
        return;
      }

      if (error.response.data.errorType === "orgNameAlreadyInUse") {
        setRegError({
          ...regError,
          regErrorState: true,
          regErrorMessage: translate("error.message.orgNameInUse"),
        });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setTimeout(() => {
          setRegError({ regErrorState: false, regErrorMessage: "" });
        }, 8000);

        setLoading(false);
        return;
      }

      if (error?.response?.status === 400) {
        setRegError({
          ...regError,
          regErrorState: true,
          regErrorMessage: translate("error.http.400"),
        });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setTimeout(() => {
          setRegError({ regErrorState: false, regErrorMessage: "" });
        }, 8000);

        setLoading(false);
        return;
      } else if (error?.response?.status === 403) {
        setRegError({
          ...regError,
          regErrorState: true,
          regErrorMessage: translate("error.http.403"),
        });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setTimeout(() => {
          setRegError({ regErrorState: false, regErrorMessage: "" });
        }, 8000);

        setLoading(false);
        return;
      } else {
        setLoading(false);
        window.location.replace(`/error`);
      }
    }
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          margin: "20px",
          height: "auto",
        }}
      >
        <ImitoIcon />
      </div>
      <div
        style={{
          marginTop: "250px",
          padding: "80px 20pt 20px",
          width: "90%",
          margin: "0 auto",
        }}
      >
        <h1 style={{ textAlign: "center", fontSize: "2rem" }}>
          <Translate contentKey="regEvent.header.imitoAccount">
            Create an imitoWound account
          </Translate>
        </h1>
        <h5 style={{ textAlign: "center" }}>
          <Translate contentKey="regEvent.header.signin">
            Already have an account? Sign in via the imitoWound app.
          </Translate>
        </h5>
        {regErrorState ? <Alert color="danger">{regErrorMessage}</Alert> : null}
        <AvForm onValidSubmit={onSubmit}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <AvGroup style={{ width: "45%" }}>
              <Label for="firstName">
                <Translate contentKey="iwoc.member.firstName">
                  First Name:
                </Translate>
              </Label>
              <AvField
                disabled={loading}
                id="firstName"
                name="firstName"
                type="text"
                value={firstName}
                onChange={onChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: translate("entity.validation.required"),
                  },
                  pattern: {
                    value: "^[a-zA-ZÀ-ž]+(?:[_ -]?[a-zA-ZÀ-ž])*$",
                    errorMessage: translate("input.validation.name"),
                  },
                  minLength: {
                    value: 2,
                    errorMessage: translate("entity.validation.minlength", {
                      min: 2,
                    }),
                  },
                  maxLength: {
                    value: 255,
                    errorMessage: translate("entity.validation.maxlength", {
                      max: 255,
                    }),
                  },
                }}
              />
            </AvGroup>
            <AvGroup style={{ width: "45%" }}>
              <Label for="lastName">
                <Translate contentKey="iwoc.member.lastName">
                  Last Name
                </Translate>
              </Label>
              <AvField
                disabled={loading}
                id="lastName"
                name="lastName"
                type="text"
                value={lastName}
                onChange={onChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: translate("entity.validation.required"),
                  },
                  pattern: {
                    value: "^[a-zA-ZÀ-ž]+(?:[_ -]?[a-zA-ZÀ-ž])*$",
                    errorMessage: translate("input.validation.name"),
                  },
                  minLength: {
                    value: 2,
                    errorMessage: translate("entity.validation.minlength", {
                      min: 2,
                    }),
                  },
                  maxLength: {
                    value: 255,
                    errorMessage: translate("entity.validation.maxlength", {
                      max: 255,
                    }),
                  },
                }}
              />
            </AvGroup>
          </div>

          <AvGroup>
            <Label for="organization">
              <Translate contentKey="form.input.organization">
                Organization
              </Translate>
            </Label>
            <AvField
              disabled={loading}
              id="organization"
              name="organization"
              type="text"
              onChange={onChange}
              value={organization}
              validate={{
                required: {
                  value: true,
                  errorMessage: translate("entity.validation.required"),
                },
                pattern: {
                  value: /^[a-zA-ZÀ-ž0-9]+([_ -]?[a-zA-ZÀ-ž0-9]?\d?)*$/,
                  errorMessage: translate("input.validation.organization"),
                },
                minLength: {
                  value: 2,
                  errorMessage: translate("entity.validation.minlength", {
                    min: 2,
                  }),
                },
                maxLength: {
                  value: 255,
                  errorMessage: translate("entity.validation.maxlength", {
                    max: 255,
                  }),
                },
              }}
            />
          </AvGroup>
          <FormGroup>
            <Label for="position">
              <Translate contentKey="form.input.position">Position</Translate>
            </Label>
            <AvField
              disabled={loading}
              id="position"
              name="position"
              type="text"
              onChange={onChange}
              placeholder={placeHolderText()}
              value={position}
              validate={{
                pattern: {
                  value: /^[a-zA-ZÀ-ž]+([_ -]?[a-zA-ZÀ-ž]?\d?)*$/,
                  errorMessage: translate("input.validation.position"),
                },
                minLength: {
                  value: 2,
                  errorMessage: translate("entity.validation.minlength", {
                    min: 2,
                  }),
                },
                maxLength: {
                  value: 255,
                  errorMessage: translate("entity.validation.maxlength", {
                    max: 255,
                  }),
                },
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="countryCode">
              <Translate contentKey="form.input.country">Country</Translate>
            </Label>
            <AvField
              disabled={loading}
              type="select"
              id="countryCode"
              name="countryCode"
              onChange={onChange}
              placeholder={placeHolderText()}
              value={countryCode}
              validate={{
                required: {
                  value: true,
                  errorMessage: translate("entity.validation.required"),
                },
              }}
            >
              <option></option>
              <optgroup>
                {DACHCountries.map((entry) => {
                  return (
                    <option key={entry.code} value={entry.code}>
                      {entry.name}
                    </option>
                  );
                })}
              </optgroup>
              <optgroup>
                {otherCountries.map((entry) => {
                  return (
                    <option key={entry.code} value={entry.code}>
                      {entry.name}
                    </option>
                  );
                })}
              </optgroup>
            </AvField>
          </FormGroup>
          <AvGroup>
            <Label for="email">
              <Translate contentKey="form.input.email">Email</Translate>
            </Label>
            <AvField
              disabled={loading}
              id="email"
              name="email"
              type="email"
              onChange={onChange}
              value={email}
              validate={{
                required: {
                  value: true,
                  errorMessage: translate("entity.validation.required"),
                },
                pattern: {
                  value: ".+@.+$",
                  errorMessage: translate("entity.validation.email"),
                },
              }}
            />
          </AvGroup>
          <AvGroup>
            <Label for="confirmEmail">
              <Translate contentKey="form.input.confirmEmail">
                Confirm Email
              </Translate>
            </Label>
            <AvField
              disabled={loading}
              id="confirmEmail"
              name="confirmEmail"
              type="email"
              onChange={onChange}
              value={confirmEmail}
              validate={{
                required: {
                  value: true,
                  errorMessage: translate("entity.validation.required"),
                },
                pattern: {
                  value: ".+@.+$",
                  errorMessage: translate("entity.validation.email"),
                },
                match: {
                  value: "email",
                  errorMessage: translate("entity.validation.emailDoesntMatch"),
                },
              }}
            />
          </AvGroup>
          <FormGroup>
            <Button
              disabled={loading}
              style={{
                width: "100%",
                background: "#e2352a",
                color: "#ffffff",
                border: "none",
                marginTop: "20px",
                height: "50px",
              }}
            >
              {loading ? (
                <Spinner />
              ) : (
                <Translate contentKey="form.action.submit">Submit</Translate>
              )}
            </Button>
          </FormGroup>
        </AvForm>
      </div>
    </>
  );
};

export default EventReg;
