import { addListener } from './utils';
import { sendHttpRequest } from './http';

const RESEND_PAUSE = 59;

export class RegistrationSuccess {
    constructor(document, window, routes) {
        this.window = window;
        this.resendButton = document.getElementById('resend-button');
        this.timeCounter = document.getElementById('time-counter');
        this.time = RESEND_PAUSE;
        this.routes = routes;
    }

    subscribeHandlers = () => {
        addListener(this.resendButton, this._resendConfirmationEmail, "click");
        if (this.resendButton && this.timeCounter) {
            setInterval(() => {
                if (this.time > 0) {
                    --this.time;
                    this.timeCounter.innerHTML = `(${this.time})`;
                } else {
                    this.resendButton.classList.remove('disabled');
                    this.timeCounter.innerHTML = ``;
                }

            }, 1000);
        }
    };


    _resendConfirmationEmail = (event) => {
        event.stopPropagation();
        if (event.target.classList.contains("disabled")) {
            return;
        }
        this.resendButton.classList.add('disabled');
        this.time = RESEND_PAUSE;

        let email = new URLSearchParams(window.location.search).get('email') || '';

        sendHttpRequest('POST', '/reg/send-link?email=' + email, {})
            .then(() => {
                console.log('resend successfull');
            })
            .catch((response) => {
                console.log('resend error');
            });

    };
}