import React from 'react';
import { Route, Switch } from 'react-router-dom';

// tslint:disable-next-line:no-unused-variable
import ErrorBoundaryRoute from './shared/error/error-boundary-route';

import MemberRoutes from './member';
import Signup from './pages/Registeration/Signup';
// import RegSuccess from './pages/Registeration/RegSuccess';
import RegCompleted from './pages/Registeration/RegCompleted';
import EventReg from './pages/Event/EventReg';

// @ts-ignore
const Routes = () => (
  <div className='view-routes'>
    <Switch>
      <ErrorBoundaryRoute
        path='/members'
        component={MemberRoutes}
      ></ErrorBoundaryRoute>
      <Route exact path='/signup' component={Signup} />
      {/* <Route path='/signup' component={Signup} />
    <Route path='/registration/success' component={RegSuccess} /> */}
      <Route path='/registration/completed' component={RegCompleted} />
      <Route exact path='/event/registration' component={EventReg} />
    </Switch>
  </div>
);

export default Routes;
