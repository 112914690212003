export interface IMember {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  status?: string;
  admin?: boolean;
}

export const defaultValue: Readonly<IMember> = {};
