import { TranslatorContext } from 'react-jhipster';

import { setLocale } from '../shared/reducers/locale';

TranslatorContext.setDefaultLocale('en');
TranslatorContext.setRenderInnerTextForMissingKeys(true);

export const languages: any = {
  en: { name: 'English' }
};

export const locales = Object.keys(languages).sort();

export const lang = new URLSearchParams(window.location.search).get('lang') || 'en';

export const registerLocale = store => {
   store.dispatch(setLocale(lang));
};
