import React, {useEffect, useState} from 'react';

const ProgressBar:React.FC = () => {
    const [progressPercent, setProgressPercent] = useState<number>(0);
    const [progressActive, setProgressActive] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setProgressPercent(100)
        },100)
        setTimeout(() => {
            setProgressActive(false)
        }, 3100)
    },[])

    return (
        <>
            <div className={progressActive ? 'progressBarContainer': 'progressBarContainerHidden'} >
                <div className='progressBar'>
                    <div className='progressBarActive'
                         style={{width:`${progressPercent}%`}} />
                </div>
            </div>
        </>

    )
};



export default ProgressBar