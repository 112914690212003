import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from "./shared/error/error-boundary";
import {AppContainer} from 'react-hot-loader';
import {Provider} from "react-redux";
import initStore from './config/store';
import { registerLocale } from './config/translation';
import {loadIcons} from "./config/icon-loader";


const store = initStore();
registerLocale(store);

loadIcons();

ReactDOM.render(
    <ErrorBoundary>
        <AppContainer>
            <Provider store={store}>
                <div>
                    <App/>
                </div>
            </Provider>
        </AppContainer>
    </ErrorBoundary>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


