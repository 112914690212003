// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';
import { Link as ScrollLink } from 'react-scroll';
// tslint:disable-next-line:no-unused-variable
import { Translate, getSortState, IPaginationBaseState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from '../shared/reducers';
import {
  deleteMemberLogo,
  getEntities,
  getMemberLogo,
  resetLogo,
} from './member.reducer';
// tslint:disable-next-line:no-unused-variable
// import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../config/constants';
import { ITEMS_PER_PAGE } from '../shared/util/pagination.constants';
import ProgressBar from './progressBar';
import OrganizationDetail from './OrganizationDetail';
import UserDetail from './UserDetail';
import MemberProvider from './MemberContext';
export interface IMemberProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ url: string }> {}

//{ xxx: string, location: ''}; //

export type IMemberState = IPaginationBaseState;

const uploadSizeLimit = 7000000;

export class Member extends React.Component<IMemberProps, IMemberState> {
  state: IMemberState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE),
    file: null,
    error: false,
  };

  componentDidMount() {
    const { getMemberLogo } = this.props;
    this.getEntities();
    getMemberLogo();
  }

  sort = (prop: any) => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(
      `${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`
    );
  }

  handlePagination = (activePage: any) =>
    this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
  };

  uploadFile(file) {
    let formData = new FormData();
    formData.append('fileToUpload', file);
    const token =
      new URLSearchParams(window.location.search).get('token') || '';
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      body: formData,
    };

    fetch('org/logo', requestOptions);
  }

  handleChange = (event) => {
    if (event.target.files[0].size > uploadSizeLimit) {
      this.setState({
        error: true,
      });
    } else {
      this.setState({
        file: event.target.files[0],
        error: false,
      });
      this.uploadFile(event.target.files[0]);
    }
  };

  handleDeleteClick = () => {
    const { deleteMemberLogo, resetLogo } = this.props;
    deleteMemberLogo();
    resetLogo();
    this.setState({
      file: null,
    });
  };

  render() {
    const { memberList, match, totalItems, permissionError, isAdmin, loading } =
      this.props;
    //const {fileSize} = this.state;
    if (permissionError) {
      return <div></div>;
    }
    if (loading) {
      return (
        <div className='spinner-container'>
          <div className='spinner-border text-danger'></div>
        </div>
      );
    }

    return (
      <MemberProvider>
        <div>
          <div>
            {isAdmin ? (
              <div className='iwocCard' style={{borderBottom:"1px solid #dbdbdb"}}>
                <div className='navLinkContainer'>
                  <ScrollLink
                    className='navLink'
                    activeClass='active'
                    to='organizationSection'
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <span className='navLink'>
                      <Translate contentKey='iwoc.member.home.organizationSettings'>
                        Organization Settings
                      </Translate>
                    </span>
                  </ScrollLink>
                </div>
                <div className='navLinkContainer'>
                  <ScrollLink
                    className='navLink'
                    activeClass='active'
                    to='userSection'
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <span className='navLink'>
                      <Translate contentKey='iwoc.member.home.personalSettings'>
                        Personal Settings
                      </Translate>
                    </span>
                  </ScrollLink>
                </div>
                <div className='navLinkContainer'></div>
                <ScrollLink
                  className='navLink'
                  activeClass='active'
                  to='membersSection'
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <span className='navLink'>
                    <Translate contentKey='iwoc.member.home.organizationMembers'>
                      Organization Memebers
                    </Translate>
                  </span>
                </ScrollLink>
              </div>
            ) : (
              <div className='iwocCard'>
                <h1>imito AG</h1>
              </div>
            )}
          </div>
          {isAdmin && (
            <div className='iwocCard' id='organizationSection'>
              {!this.state.file && !this.props.currentLogo && (
                <>
                  <h2>
                    <Translate contentKey='iwoc.member.home.addLogo'>
                      Add Customer Logo
                    </Translate>
                  </h2>
                  <div className='mb-3 members-warn'>
                    <Translate contentKey='iwoc.member.home.logoWarning'>
                      Note: Please note that PNG, JPG sizes should not exceed 7
                      Mb.
                    </Translate>
                  </div>

                  <label className='iwocInputWrapper'>
                    <input
                      type='file'
                      className='iwocInput'
                      accept='.jpg, .jpeg, .png'
                      size={uploadSizeLimit}
                      onChange={this.handleChange}
                    />
                    <Translate contentKey='iwoc.member.home.logoBtn'>
                      Upload picture
                    </Translate>
                    <FontAwesomeIcon icon='upload' className='iwocBtnTitle' />
                  </label>
                </>
              )}

              {this.state.error && (
                <Alert color='danger' className='iwocAlert'>
                  <p className='iwocAlertTitle'>
                    <Translate contentKey='iwoc.member.home.uploadWarning'>
                      Invalid format or size
                    </Translate>
                  </p>

                  <span>
                    <Translate contentKey='Iwoc.member.home.uploadWarningText'>
                      Invalid file format or maximum file size exceeded. JPEG,
                      PNG and MP4 are supported - maximum 7 MB per file.
                    </Translate>
                  </span>
                </Alert>
              )}
              {this.state.file && (
                <div className='iwocPrewContainer'>
                  <h2>
                    <Translate contentKey='Iwoc.member.home.companyLogo'>
                      Company Logo
                    </Translate>
                  </h2>
                  <div className='iwocContainer'>
                    <img
                      src={URL.createObjectURL(this.state.file)}
                      alt='Customer logo'
                      className='iwocPrew'
                    />

                    <Button
                      size='sm'
                      className='btn-iconic pt-10'
                      onClick={this.handleDeleteClick}
                    >
                      <FontAwesomeIcon icon='trash' />{' '}
                    </Button>
                  </div>
                </div>
              )}

              {this.props.currentLogo && !this.state.file && (
                <div className='iwocPrewContainer'>
                  <div style={{ marginBottom: '20px' }}>
                    <h4>
                      {' '}
                      <Translate contentKey='iwoc.member.home.organizationSettings'>
                        Organization Settings
                      </Translate>
                    </h4>
                    <span>
                      <Translate contentKey='iwoc.member.companyDetail'>
                        Your company details on the wond report.
                      </Translate>
                    </span>
                  </div>
                  <h2>
                    <Translate contentKey='iwoc.member.home.companyLogo'>
                      Company Logo
                    </Translate>
                  </h2>
                  <div className='iwocContainer'>
                    <img
                      src={'data:image/png;base64, ' + this.props.currentLogo}
                      alt='Customer logo'
                      className='iwocPrew'
                    />

                    <Button
                      size='sm'
                      className='btn-iconic pt-10'
                      onClick={this.handleDeleteClick}
                    >
                      <FontAwesomeIcon icon='trash' />{' '}
                    </Button>
                  </div>
                </div>
              )}

              {!this.state.error && this.state.file && <ProgressBar />}

              <OrganizationDetail />
            </div>
          )}
           {isAdmin &&  <div style={{borderBottom:"1px solid #dbdbdb"}}></div>}
         
          <div className='iwocCard' id='userSection'>
            {/* {isAdmin && <UserDetail />} */}
            <UserDetail />
          </div>
          <div style={{borderBottom:"1px solid #dbdbdb"}}></div>
          <div className='iwocCard' id='membersSection'>
            <h2 id='member-heading'>
              <Translate
                contentKey='iwoc.member.inorg'
                interpolate={{ num: totalItems }}
              >
                Members
              </Translate>
            </h2>
            {isAdmin ? (
              <div>
                <div className='mb-3 members-warn'>
                  <Translate contentKey='iwoc.member.home.warning'>
                    Note: all users inside of the organisation will be able to
                    access all of patients, wounds and assessments documented.
                  </Translate>
                </div>

                <Link
                  to={`${match.url}/new`}
                  className='btn btn-secondary jh-create-entity'
                  id='jh-create-entity'
                >
                  <Translate contentKey='iwoc.member.home.addMember'>
                    Add member
                  </Translate>
                </Link>
              </div>
            ) : null}
            <div style={{ marginTop: '20px' }}>
              <div>
                {memberList.map((member, i) => (
                  <div className='memberCard' key={`entity-${i}`}>
                    <div className='memberLeft'>
                      <div className='memberName font-weight-bold'>
                        {(member.firstName || '') +
                          ' ' +
                          (member.lastName || '')}
                      </div>
                      <div className='memberEmail font-weight-normal'>
                        {member.email || ''}
                      </div>
                      <div
                        className='memberBadges'
                        style={{ marginTop: '5px' }}
                      >
                        {member.admin ? (
                          <span className='badge badge-dark'>
                            <Translate contentKey='iwoc.member.owner'>
                              Owner
                            </Translate>
                          </span>
                        ) : null}
                        {member.status === 'active' ? (
                          <span className='badge badge-success'>
                            <Translate contentKey='iwoc.member.active'>
                              Active
                            </Translate>
                          </span>
                        ) : null}
                        {member.status === 'invited' ? (
                          <span className='badge badge-secondary'>
                            <Translate contentKey='iwoc.member.pending'>
                              Pending
                            </Translate>
                          </span>
                        ) : null}
                        {member.status === 'inactive' ? (
                          <span className='badge badge-warning'>
                            <Translate contentKey='iwoc.member.inactive'>
                              Inactive
                            </Translate>
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className='memberRight'>
                      {isAdmin && !member.admin ? (
                        <Button
                          tag={Link}
                          to={`${match.url}/${member.email}/delete`}
                          size='sm'
                          className='btn-iconic pt-10'
                        >
                          <FontAwesomeIcon icon='trash' />{' '}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>

              {/*<Row className="justify-content-center">*/}
              {/*<JhiPagination*/}
              {/*items={getPaginationItemsNumber(totalItems, this.state.itemsPerPage)}*/}
              {/*activePage={this.state.activePage}*/}
              {/*onSelect={this.handlePagination}*/}
              {/*maxButtons={5}*/}
              {/*/>*/}
              {/*</Row>*/}
            </div>
          </div>
        </div>
      </MemberProvider>
    );
  }
}

const mapStateToProps = ({ member }: IRootState) => ({
  isAdmin: member.isAdmin,
  memberList: member.entities,
  totalItems: member.totalItems,
  permissionError: member.permissionError,
  loading: member.loading,
  currentLogo: member.memberLogo,
});
const mapDispatchToProps = {
  getEntities,
  getMemberLogo,
  deleteMemberLogo,
  resetLogo,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Member);
