// Austria
// Germany
// Switzerland
// --- (DIVIDER)
// Belgium
// Bulgaria
// Croatia
// Cyprus
// Czech Republic
// Denmark
// Estonia
// Finland
// France
// Greece
// Hungary
// Ireland
// Italy
// Latvia
// Lithuania
// Luxembourg
// Malta
// Netherlands
// Poland
// Portugal
// Romania
// Slovakia
// Slovenia
// Spain
// Sweden
// United Kingdom
export const DACHCountries = [
  {
    code: "AT",
    name: "Austria",
  },
  {
    code: "DE",
    name: "Germany",
  },
  {
    code: "CH",
    name: "Switzerland",
  },
];

export const otherCountries = [
  {
    code: "BE",
    name: "Belgium",
  },
  {
    code: "BG",
    name: "Bulgaria",
  },
  {
    code: "HR",
    name: "Croatia",
  },
  {
    code: "CY",
    name: "Cyprus",
  },
  {
    code: "CZ",
    name: "Czech Republic",
  },
  {
    code: "DK",
    name: "Denmark",
  },
  {
    code: "EE",
    name: "Estonia",
  },
  {
    code: "FI",
    name: "Finland",
  },
  {
    code: "FR",
    name: "France",
  },
  {
    code: "GR",
    name: "Greece",
  },
  {
    code: "HU",
    name: "Hungary",
  },
  {
    code: "IE",
    name: "Ireland",
  },
  {
    code: "IT",
    name: "Italy",
  },
  {
    code: "LV",
    name: "Latvia",
  },
  {
    code: "LT",
    name: "Lithuania",
  },
  {
    code: "LU",
    name: "Luxembourg",
  },
  {
    code: "MT",
    name: "Malta",
  },
  {
    code: "NL",
    name: "Netherlands",
  },
  {
    code: "PL",
    name: "Poland",
  },
  {
    code: "PT",
    name: "Portugal",
  },
  {
    code: "RO",
    name: "Romania",
  },
  {
    code: "SK",
    name: "Slovakia",
  },
  {
    code: "SI",
    name: "Slovenia",
  },
  {
    code: "ES",
    name: "Spain",
  },
  {
    code: "SE",
    name: "Sweden",
  },
  {
    code: "GB",
    name: "United Kingdom",
  },
];
