export const sendHttpRequest = (method, url ,data) => {
    const promise = new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(method, url, true);

        xhr.responseType = 'json';
        xhr.setRequestHeader('Accept', 'application/json');

        if (data) {
            xhr.setRequestHeader('Content-Type', 'application/json');
        }

        xhr.onload = () => {
            if(xhr.status >= 400){
                reject(xhr.response);
            } else {
                resolve(xhr.response);
            }
        };

        xhr.onerror = () => {
            reject('Something went wrong.');
        };

        xhr.send(JSON.stringify(data));
    });

    return promise;
};