import React from 'react';
import { Translate } from 'react-jhipster';
import styles from './regCompleted.module.css';
import { ImitoIcon } from '../../assets';
import { lang } from '../../config/translation';

console.log('language', lang);

const RegCompleted = () => {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          margin: '20px 20px',
          height: '30px',
        }}
      >
        <ImitoIcon style={{ marginBottom: '20px' }} />
      </div>
      <div
        style={{
          height: '90vh',
          marginTop: '60px',
        }}
        className={styles.coontainer}
      >
        <img
          src='/images/web-img-success.png'
          alt=''
          srcSet='/images/web-img-success.png 1x, /images/web-img-success@2x.png 2x'
        />

        <h1
          style={{
            fontFamily: 'Inter',
            fontWeight: 600,
            letterSpacing: '-0.3px',
          }}
        >
          <Translate contentKey='thanksPage.message.accountCreated'>
            Your free 30 days imitoWound account has been created.
          </Translate>
        </h1>
        <h6
          style={{
            fontFamily: 'Inter',
            fontWeight: 'normal',
            letterSpacing: '-0.3px',
            textAlign: 'center',
            padding: '5px',
          }}
        >
          <Translate contentKey='thanksPage.message.emailSent'>
            The email with the credentials was sent to you. Use these
            credentials to log in into imitoWound App.
          </Translate>
        </h6>
        <div
          style={{
            background: '#e2a22a0d',
            padding: '12px',
          }}
        >
          <h3
            style={{
              fontSize: '18px',
              fontWeight: 600,
            }}
          >
            <Translate contentKey='thanksPage.message.starterKit'>
              Show the email you’ve got to us at our booth and you get an
              exclusive{' '}
              <span aria-label='gift emoji' role='img'>
                🎁
              </span>{' '}
              Starter Kit Package!
            </Translate>
          </h3>
        </div>
        <div
          style={{
            marginTop: '20px',
            width: '90%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <a
            href='https://apps.apple.com/app/imitowound-digital-woundcare/id1093124845?l=en'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src='/images/web-appstore.png'
              alt='App Store'
              srcSet={`/images/img-appstore-${lang}_GB.png 1x, /images/img-appstore-${lang}_GB@2x.png 2x, /images/img-appstore-${lang}_GB@3x.png 3x`}
            />
          </a>
          <a
            href='https://play.google.com/store/apps/details?id=io.imito.imitowound'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src='/images/web-googleplay'
              alt='Google PlayStore'
              srcSet={`/images/img-playstore-${lang}_GB@3x.png 3x, /images/img-playstore-${lang}_GB@2x.png 2x, /images/img-playstore-${lang}_GB.png 1x`}
            />
          </a>
        </div>
      </div>
    </>
  );
};
export default RegCompleted;
