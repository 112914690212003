import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from '../shared/error/error-boundary-route';

import Member from './member';
import MemberUpdate from './member-update';
import MemberDeleteDialog from './member-delete-dialog';

// @ts-ignore
const Routes = ({match}) => (
    <>
        <Switch>
            <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MemberUpdate}/>
            {/*<ErrorBoundaryRoute exact path={`${match.url}/:id/view`} component={MemberDetail}/>*/}
            <ErrorBoundaryRoute  path={match.url} component={Member}/>
        </Switch>

        <Switch>
            <ErrorBoundaryRoute path={`${match.url}/new`} component={MemberUpdate}/>
            <ErrorBoundaryRoute path={`${match.url}/:email/delete`} component={MemberDeleteDialog}/>
        </Switch>

    </>
);

export default Routes;
